
<template>
    <div >    
        <span >{{msg}}</span>
    </div>
</template>

<script>

export default {
    name: "login-authorize",
    props: {
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            msg: '',
            redirect: '',
        }
    },
    methods: {
        getLoginUser(){
            let user = localStorage.getItem("user")
            // console.log(login_info)
            let login_info = JSON.parse(user);
            return login_info
        },
        // end 
        initNewToken(user){
            return JSON.stringify({
                uid: user.uid,
                name: user.name,
                avatar: user.avatar,
                access_token: user.access_token,
                group_id: user.group_id,
                group_name: user.group_name,
            })
        },
        // end 
    },
    mounted () {
        let self = this;
        // 获取传参
        // console.log(self.$route)
        // console.log(self.$route.query.source_url)
        let login_info = this.getLoginUser()
        if (login_info){
            // alert(self.$route.query.site)
            if (self.$route.query.source_url){
                let new_token = self.initNewToken(login_info)
                // self.$router.push({ path: self.$route.query.source_url }).catch(()=>{});
                window.location.href = self.$route.query.source_url + "?logininfo=" + encodeURI(new_token); // 跳转到第三方
            }else{
                self.$router.push({ path: '/index' }).catch(()=>{});
            }
        }else{
            // 跳转到登录页面
            // alert(self.$route.query.site)
            self.$router.push({ 
                path: '/login/index',
                query: {
                    redirect: self.$route.query.source_url,
                    site: self.$route.query.site ,
                }
            }).catch(()=>{});
        }
    },
    watch: {
    },
    computed: {}
}
</script>
